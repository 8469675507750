import React, { useContext } from "react";
import styled from "@emotion/styled";
import { rhythm, scale } from "utils/typography";
import useTranslations from "hooks/useTranslations";

import LocalizedLink from "components/localizedLink";
import { ThemeContext } from "context/theme-context";
import { LocaleContext } from "context/locale-context";
import { mq } from "utils/helper";

const Title = styled.h2((props) => ({
  marginBottom: rhythm(3),
  marginTop: 0,
  color: props.colors.light,
  direction: props.isRTL ? "rtl" : "ltr",
  [mq[1]]: {
    fontWeight: 800,
    margin: `${rhythm(3)} 0 ${rhythm(2)} 0`,
  },
}));

const H2 = styled.h2((props) => ({
  margin: 0,
  alignSelf: "start",
  ...scale(0.7),
  [mq[1]]: {
    fontWeight: 800,
    margin: rhythm(1),
    marginRight: props.isRTL ? 0 : "inherit",
    marginLeft: props.isRTL ? "inherit" : 0,
  },
}));

const Link = styled.a((props) => ({
  color: props.colors.light,
  borderBottom: `2px solid ${props.colors.primary}`,
  transition: "0.25s",
  ":hover, :active": {
    color: props.colors.primary,
  },
}));

const Desc = styled.p({
  ...scale(0.3),
  fontWeight: "bold",
  [mq[1]]: {
    fontWeight: 500,
    ...scale(0.3),
    marginBottom: rhythm(1),
    marginTop: rhythm(1),
  },
});

function MnemonicsText({ locale }) {
  const { colors } = useContext(ThemeContext);

  const en = (
    <>
      <Desc>
        Yemeni Archive is a project of
        <Link
          href="https://mnemonic.org"
          rel="noopener noreferrer"
          target="_blank"
          colors={colors}
        >
          {" "}
          Mnemonic{" "}
        </Link>
        , a non-profit organisation dedicated to archiving disappearing digital
        material
      </Desc>
    </>
  );

  const ar = (
    <>
      <Desc>
        الأرشيف اليمني هو أحد مشاريع
        <Link
          href="https://mnemonic.org"
          rel="noopener noreferrer"
          target="_blank"
          colors={colors}
        >
          {" "}
          Mnemonic{" "}
        </Link>
        وهي منظمة غير ربحيّة مُكرّسة لأرشفة المواد الرقميّة المُهدّدة بالإختفاء
      </Desc>
    </>
  );
  return locale === "ar" ? ar : en;
}

function ProjectsText({ locale }) {
  const { colors } = useContext(ThemeContext);

  const en = (
    <>
      <Desc>
        We have verified and{" "}
        <Link as={LocalizedLink} to="investigations" colors={colors}>
          published
        </Link>{" "}
        thousands of pieces of{" "}
        <Link as={LocalizedLink} to="data-archive" colors={colors}>
          data
        </Link>{" "}
        related to human rights violations in Yemen
      </Desc>
    </>
  );

  const ar = (
    <>
      <Desc>
        لقد تثبتنا و{" "}
        <Link as={LocalizedLink} to="investigations" colors={colors}>
          نشرنا
        </Link>{" "}
        الآلاف من وحدات{" "}
        <Link as={LocalizedLink} to="data-archive" colors={colors}>
          البيانات
        </Link>{" "}
        المتعلقة بانتهاكات حقوق الإنسان في اليمن
      </Desc>
    </>
  );
  return locale === "ar" ? ar : en;
}

function ArchivesText({ locale }) {
  const { colors } = useContext(ThemeContext);

  const en = (
    <>
      <Desc>
        We create archives, investigate and memorialise the data we have
        collected, build tools, train others and work with platforms to reduce
        the impact of their harmful policies. Visit
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://syrianarchive.org"
          colors={colors}
        >
          {" "}
          Syrian Archive{" "}
        </Link>
        ,
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://sudanesearchive.org"
          colors={colors}
        >
          {" "}
          Sudanese Archive{" "}
        </Link>
        , and
        <Link
          href="https://ukrainianarchive.org/"
          rel="noopener noreferrer"
          target="_blank"
          colors={colors}
        >
          {" "}
          Ukrainian Archive
        </Link>
      </Desc>
    </>
  );

  const ar = (
    <>
      <Desc>
        نحن نبني الأراشيف، نُحقّق، ونحفظ البيانات التي جمعناها. بالإضافة إلى
        بناء الأدوات، تدريب الآخرين، والتواصل مع المنصات للحدّ من الآثار السلبية
        لسياساتها. زُر
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://syrianarchive.org/ar"
          colors={colors}
        >
          {" "}
          الأرشيف السوري{" "}
        </Link>
        ،
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://sudanesearchive.org/ar"
          colors={colors}
        >
          {" "}
          الأرشيف السوداني{" "}
        </Link>
        ،
        <Link
          href="https://ukrainianarchive.org"
          rel="noopener noreferrer"
          target="_blank"
          colors={colors}
        >
          {" "}
          Ukrainian Archive{" "}
        </Link>
      </Desc>
    </>
  );
  return locale === "ar" ? ar : en;
}

function WhoWeAre() {
  const tr = useTranslations();
  const locale = useContext(LocaleContext);
  const { colors } = useContext(ThemeContext);
  const isRTL = locale === "ar";
  return (
    <div
      css={{
        paddingTop: rhythm(3.7),
        paddingBottom: rhythm(3.7),
        backgroundColor: colors.dark,
        backgroundImage:
          "linear-gradient(180deg,rgba(0,0,0,0.63) 0%,rgba(179, 44, 80, 0.6) 100%)",
        [mq[1]]: {
          paddingTop: rhythm(1),
          paddingBottom: rhythm(1),
        },
      }}
    >
      <div
        css={{
          maxWidth: "950px",
          margin: "auto",
          [mq[2]]: {
            margin: `0 ${rhythm(1)}`,
          },
        }}
      >
        <Title isRTL={isRTL} colors={colors}>
          {tr("Who we are")}
        </Title>
        <div
          css={{
            display: "grid",
            alignItems: "center",
            gridTemplateColumns: "0.6fr 1.4fr",
            gridRowGap: rhythm(2.8),
            gridTemplateAreas:
              '"who who-desc" "what what-desc" "lost lost-desc"',
            maxWidth: "950px",
            margin: "auto",
            color: colors.light,
            direction: isRTL ? "rtl" : "ltr",
            [mq[1]]: {
              display: "block",
              margin: rhythm(1),
            },
          }}
        >
          <H2
            css={{
              gridArea: "who",
            }}
          >
            {tr("Mnemonic")}
          </H2>
          <div css={{ gridArea: "who-desc", alignSelf: "start" }}>
            <MnemonicsText locale={locale} />
          </div>
          <H2 css={{ gridArea: "what" }}>{tr("Projects")}</H2>
          <div css={{ gridArea: "what-desc", alignSelf: "start" }}>
            <ProjectsText locale={locale} />
          </div>
          <H2 css={{ gridArea: "lost" }}>{tr("Archives")}</H2>
          <div css={{ gridArea: "lost-desc", alignSelf: "start" }}>
            <ArchivesText locale={locale} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WhoWeAre;
